import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";

import { functions } from "../../firebase.js";
import OrderTable from '../../components/StudentsTable';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';

function Students() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllUsers = httpsCallable(functions, 'getAllUsers');
    getAllUsers()
      .then((result) => {
        setUsers(result.data)
        setLoading(false)
      });
  },[]);

  return (
    <Box sx={{
      height: "100%",
    }}>
      {!loading && <OrderTable users={users} /> }
      {loading && (
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Students;
