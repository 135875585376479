import React from "react";

function Lessons() {
  return (
    <div style={{marginLeft: '2em'}}>
      <h3>Slides</h3>
      <ul>
        <li>
          <a href="https://docs.google.com/presentation/d/19f2961lvWtrXi11v6JWIqFIJILlYqGE4FwnVEaMCC10/present?slide=id.p">
            Les 1 - Naar Grauwengrijs
            </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1byWjzRgzbLX-pB6eHh0qqAI6y1Ev9uzG7dwiZdWsN3Q/present?slide=id.p">
            Les 2 - De Kapotte Brug
            </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1U9JkYAt1sbCwES8mET7yiNSgfJtNlHwaHGkbZBxG40A/present?slide=id.p">
            Les 3 - Het Vissershuisje
            </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1b5tKooN0W-Yrj4JUQQunPAeL2QZl-7YjcLUva7n6PW4/present#slide=id.p">
            Les 4 - Octo's Troep
            </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1hzviURciI2aejWcT2Tz8QnW7BANpHop1ZDX5wifAO3o/present#slide=id.p">
            Les 5 - Vliegensvlug
            </a>
        </li>
        <li>
          <a href="https://docs.google.com/presentation/d/1MzIuC_RtgKT0cUJFSxVO5zMWNAOf4PYSuOvO8cAaehI/present#slide=id.p">
            Les 6 - Tiny House
            </a>
        </li>
      </ul>


      <h3>Lesdocumenten</h3>
      <ul>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-1.pdf?alt=media&token=b985ec04-9a29-449a-a1ff-07f5b6bce6a2">
            Lesdocument 1 - Naar Grauwengrijs
            </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-2.pdf?alt=media&token=ad4150f5-8c96-4384-afa4-e81eebd42314">
            Lesdocument 2 - De Kapotte Brug
            </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-3.pdf?alt=media&token=dad29d04-fd1d-4cf2-9489-0e0e8b8706b0">
            Lesdocument 3 - Het Vissershuisje
            </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-4.pdf?alt=media&token=60ee4e8d-7805-4655-9ee0-3ea4092db7e6">
            Lesdocument 4 - Octo's Troep
            </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-5.pdf?alt=media&token=189058cc-6b00-4922-becd-3c6c8c9e15fc">
            Lesdocument 5 - Vliegensvlug
            </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/brickmeister-prod.appspot.com/o/lesdocumenten%2FChallenge-6.pdf?alt=media&token=90d92dba-f094-4f7f-9fe0-32f0bbb254cd">
            Lesdocument 6 - Tiny House
            </a>
        </li>
      </ul>
    </div>
  );
}

export default Lessons;
