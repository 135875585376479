import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import _ from "lodash";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { query, getDocs, collection, where } from "firebase/firestore";
import { auth, db } from "../../firebase.js";

import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";

const errorMessages = {
  "default": "Het inloggen is helaas niet gelukt",
  "auth/wrong-password": "Het opgegeven password klopt niet."
}

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [error, setError] = useState("");

  async function loginOAuth() {
    const domain = email.split("@")[1];
    if (!domain) {
      setError("Het emailadres wat je hebt opgegeven klopt niet.");
      setLoading(false);
      return;
    }

    // Get tenant that matches the user's domain.
    let tenant;
    try {
      const snapshot = await getDocs(query(collection(db, "tenants"), where("domains", "array-contains", domain)));
      if (snapshot.docs.length) {
        tenant = {
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data()
        };
      }
    } catch (err) {
      setError("Unable to get tenant: " + err.message);
    }

    // No tenant was found so falling back to the Demo tenant.
    if (!tenant.name) {
      try {
        const snapshot = await getDocs(query(collection(db, "tenants"), where("name", "==", "Demo")));
        if (!snapshot.docs.length) {
          setError("Geen tenant kunnen vinden.");
          setLoading(false);
          return;
        }
        tenant ={
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data()
        }
      } catch (err) {
        setError("Unable to get retail tenant: " + err.message);
      }
    }

    // Abort if the provider is email.
    if (tenant.providerId === "email") {
      setLoading(false);
      return;
    }

    const provider = new OAuthProvider(tenant.providerId);
    const customParams = {};
    if (tenant.providerId === "google.com") {
      customParams.hd = domain;
      customParams.prompt = "select_account";
    }
    if (tenant.externalTenantId) {
      customParams.tenant = tenant.externalTenantId;
    }
    provider.setCustomParameters(customParams);

    try {
      auth.tenantId = tenant.tenantId;
      await signInWithPopup(auth, provider);
      navigate("/")
    } catch (err) {
      console.log(err);
      setError(errorMessages[err.code] || errorMessages.default);
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setInfo("");
    setError("");
    setLoading(true);

    await loginOAuth();
    setLoading(false);
  }

  // If authenticated redirect the user to its orginal destination.
  if (auth.currentUser) {
    return <Navigate replace to={_.get(navigate.state, "from", "/")} />;
  }

  return (
    <form id="login" onSubmit={onSubmit}>
      <Box sx={{ p: 6 }}>
        <Typography
          level="h1"
          textColor="text.secondary"
          fontSize="xl3"
          sx={{ mb: 6 }}
        >
          Login
        </Typography>

        {!!info && <p>{info}</p>}
        {!!error && <p>{error}</p>}

        <div>
          <FormControl sx={{ mb: 4, maxWidth: "400px" }}>
            <FormControl>
              <FormLabel>Emailadres</FormLabel>
              <Input
                variant="outlined"
                name="email"
                type="email"
                error={!!errorEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
          </FormControl>
          <Button loading={loading} onClick={onSubmit} sx={{ mt: 1 }}>
            Inloggen
          </Button>
        </div>
      </Box>
    </form>
  );
}

export default Login;

/*
  async function loginEmailPassword() {
    setError("");
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password)
      navigate("/")
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setError(errorMessages[err.code] || errorMessages.default);
    }
  }
*/
