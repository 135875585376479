import * as React from 'react';
import { CssVarsProvider, getInitColorSchemeScript } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import { onAuthStateChanged, getRedirectResult } from "firebase/auth";

// Icons import
import MenuIcon from '@mui/icons-material/Menu';

// Custom
import Layout from '../../components/Layout.tsx';
import ColorSchemeToggle from '../../components/ThemeToggle.js';
import TeamNav from '../../components/TeamNav.js';
import { auth } from "../../firebase.js";

export default function App(props) {
  const [initialized, setInitialized] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    getRedirectResult(auth)
      .then(result => {
        console.info("auth provider result", result);
      })
      .catch(err => {
        console.log(err);
      });
    return onAuthStateChanged(auth, (user) => setInitialized(true));
  }, []);

  return (
    <CssVarsProvider disableTransitionOnChange>
      {getInitColorSchemeScript()}
      <CssBaseline />
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <TeamNav />
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
        <Layout.Header>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1.5,
            }}
          >
            <IconButton
              variant="outlined"
              size="sm"
              onClick={() => setDrawerOpen(true)}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" fontWeight="xl">
              <img src="/bm_logo.svg" width="150" />
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            <ColorSchemeToggle />
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          {auth.currentUser && <TeamNav /> }
        </Layout.SideNav>
        <Layout.Main>
          {!initialized && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography textColor="text.tertiary" fontSize="xl2" fontWeight="md">
                <img src="/bm_logo.svg" width="150" />
              </Typography>
            </Box>
          )}
          {initialized && props.children}
        </Layout.Main>
      </Layout.Root>
    </CssVarsProvider>
  );
}
