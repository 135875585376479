import React from "react";
import { Route, Routes, Navigate, BrowserRouter, useLocation } from "react-router-dom";

import { auth } from "./firebase";
import {
  App,
  Students,
  Lessons,
  NotFound,
  Login
} from "./pages";

const publicRoutes = ["/login"];

function Private(props) {
  const location = useLocation();
  if (!auth.currentUser) {
    return <Navigate replace to="/login" state={{ from: location.pathname }} />;
  }
  return props.children;
}

function Router() {
  return (
    <BrowserRouter>
      <App publicRoutes={publicRoutes}>
        <Routes>
          <Route
            path="/"
            element={
              <Private>
                <Lessons name="Lessons" />
              </Private>
            }
          />
          <Route
            path="/leerlingen"
            element={
              <Private>
                <Students name="Studenten" />
              </Private>
            }
          />
          <Route
            path="/lessen"
            element={
              <Private>
                <Lessons name="Lessons" />
              </Private>
            }
          />
          <Route
            path="/login"
            element={
              <Login name="Login" />
            }
          />
          <Route
            path="*"
            element={
              <Private>
              <NotFound name="NotFound" />
              </Private>
            }
            exact
          />
        </Routes>
      </App>
    </BrowserRouter>
  );
}

export default Router;

/*
          /*<Route path="/login" element={<Login name="Login" />} />
          <Route path="/action" element={<Action name="Action" />} />
          <Route path="/verify-email" element={<VerifyEmail name="VerifyEmail" />} />
          <Route path="/sign-in" element={<SignIn name="SignIn" />} />
          <Route path="/forgot-password" element={<ForgotPassword name="ForgotPassword" />} />
          <Route path="/reset-password" element={<ResetPassword name="ResetPassword" />} />
          <Route path="/signup" element={<Signup name="Signup" />} />

      */
