/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

// icons
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderTable({ users }) {
  const studenten = users.map((u) => {
    const exercises = u.projects[0].exercises;
    const completed = u.projects[0].exercises.filter((e) => e.solution);
    return {
      id: u.uid,
      date: null,
      status: `afgerond ${completed.length}/${exercises.length}`,
      customer: {
        initial: u.email[0],
        name: u.email.split('@')[0],
        email: u.email,
      },
    };
  });
  const [order] = React.useState('desc');
  return (
    <div>
      <Sheet
        className="OrderTableContainer"
        sx={{
          display: {  },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 0,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 'auto', padding: '12px 6px' }}>Leerling</th>
              <th style={{ width: 160, padding: '12px 6px' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {stableSort(studenten, getComparator(order, 'id')).map((row) => (
              <tr key={row.id}>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Avatar size="sm">{row.customer.initial}</Avatar>
                    <div>
                      <Typography level="body-xs">{row.customer.name}</Typography>
                      <Typography level="body-xs">{row.customer.email}</Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    startDecorator={
                      {
                        Afgerond: <CheckRoundedIcon />,
                        Bezig: <AutorenewRoundedIcon />,
                      }[row.status]
                    }
                    color={
                      {
                        Afgerond: 'success',
                        Open: 'neutral',
                        "Niet gestart": 'progress',
                      }[row.status]
                    }
                  >
                    {row.status}
                  </Chip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>

    </div>
  );
}


/*

<Box
className="Pagination-laptopUp"
sx={{
  p: 1,
  gap: 1,
  [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
  display: {
    xs: 'none',
    md: 'flex',
  },
}}
>
<Button
  size="sm"
  variant="outlined"
  color="neutral"
  startDecorator={<KeyboardArrowLeftIcon />}
>
  Vorige
</Button>

<Box sx={{ flex: 1 }} />
{['1', '2', '3', '…', '8', '9', '10'].map((page) => (
  <IconButton
    key={page}
    size="sm"
    variant={Number(page) ? 'outlined' : 'plain'}
    color="neutral"
  >
    {page}
  </IconButton>
))}
<Box sx={{ flex: 1 }} />

<Button
  size="sm"
  variant="outlined"
  color="neutral"
  endDecorator={<KeyboardArrowRightIcon />}
>
  Volgende
</Button>
</Box>


*/
